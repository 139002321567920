import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function DesignRegistration() {
  /* Slider */
  // const designSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Design Registration`,
  //     content: `Secure design Registration for Your Products and Brands`,
  //     image: "/imgs/registration/ngo/fcra-registration.png",
  //     alt_tag: "Best Online Secure design Registration Near Bangalore",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Design Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `14999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const designAboutData = {
    header: `Online Design Registration`,
    sub_title: `Design registration for for your business and organizations logo,patents,trademark etc., `,
    content_paragraph: [
      `Shape, setup, example, or ornament, or composition of lines, shading, or blend used in 
      an article are all registered under the Design Act of 2000. The registered design can include 
      three-dimensional highlights, such as the shape or arrangement of an article, or two-dimensional 
      highlights, such as patterns or embellishments.`,

      // `The registration grants the creator the right to use the design for ten years the time frame can
      // be extended for an additional five years.`,
    ],
  };

  /* Scroll Nav Data */
  const designScrollId = [
    {
      id: `#checklist`,
      heading: `Checklist`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#benefitss`,
      heading: `Benefits`,
    },
    {
      id: `#penalty`,
      heading: `Penalty`,
    },
  ];

  /* Img Content Component Data */
  const designIcData = {
    id: 'checklist',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Design Registration Checklists',
    image: '/imgs/registration/ipr/td8.png',
    alt_tag: 'Design Registration',
    points: [
      {
        head: ``,
        content: `Form 1 in the prescribed format`,
        icon: true,
      },
      {
        head: '',
        content: `Representations should be prepared as prescribed under Rule 12, 13and 14 of The
        Designs Rules and should be submitted in duplicate.`,
        icon: true,
      },
      {
        head: '',
        content: `Form-21 If the applicant files copy of General Power of authority (GPA), it should be endorsed
        with the design application number, with which the original GPA has been filed.`,
        icon: true,
      },
      {
        head: '',
        content: `Form-24 in prescribed format, Original Priority document under Rule 15 of The Designs Rules.`,
        icon: true,
      },
      {
        head: '',
        content: `Authenticated English translated copy of the Priority document, Assignment in original, Form-24 in prescribed format`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const designCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'The Design Registration Procedure In India',
    content: [
      `The registration of current plans and their security in India are governed by the Designs Act of 
    2000. The administrative office in charge of registering the mechanical plan is the Office of the Controller 
    General of Patents, Trademarks, and Designs.`,
      `It disapproves of all standards of improvement as well as all trademarks, service marks, and creative works.
     The structure must be brand-new or special, and it cannot have been supplied in any nation prior to the 
     registration application date.`,
    ],
    body: [
      {
        head: ``,
        points: `Verify Prior Registration`,
        icon: true,
      },
      {
        head: ``,
        points: `Design registration`,
        icon: true,
      },
      {
        head: ``,
        points: `Duration of the design`,
        icon: true,
      },
      {
        head: ``,
        points: `Examining and approving`,
        icon: true,
      },
      {
        head: ``,
        points: `Design Infraction`,
        icon: true,
      },
      {
        head: ``,
        points: `Application and Claim Required`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const designCiData = {
    id: 'penalty',
    background: 'main-content',
    mt_div: '',
    mt_img: 'mt-5',
    header: 'Penalty For Copying A Registered Design',
    paragraph: ``,
    points: [
      {
        content: `If someone violates the copyright in a registered design, they must pay the registered owner a 
        total of Rs. 25,000 for each infraction.`,
        icon: true,
      },
      {
        content: `Up to a maximum of Rs. 50,000 that can be recovered as an 
        agreement obligation for any given arrangement. As stated in Section 22(2), the whole scale absolute
         recoverable would not exceed Rs. 50,000. (a).`,
        icon: true,
      },
      {
        content: `Any court below the court of the district judge should not record the lawsuit for infringement, recovery of damages,
         or other claims.`,
        icon: true,
      },
      {
        content: `The registered proprietor may file a lawsuit to seek damages for any such refutation as 
         well as to demand an appropriate amount.`,
        icon: true,
      },
    ],
    image: '/imgs/business/checklist.png',
    alt_tag: 'Required Documents for Copyright Registration',
  };

  /* ImgSlider Data */
  const designImages = [
    '/imgs/features.png',
    '/imgs/business-plan.png',
    '/imgs/registration/msme/ms-me.png',
  ];

  /*  Slider Content Component Data */
  const designSCData = {
    id: 'benefitss',
    background: [],
    mt_div: 'mt-4',
    header: 'Patent Registration Advantages',
    paragraph: `The market has its own unique process for defining uniqueness, which helps new products in carving
    out a niche for themselves among competing products.`,
    content: [
      {
        points: `Affidavits and Declarations Needed for Design Registration Archives Additional public records may
         be made available in stages for a fee.`,
        icon: true,
      },
      {
        points: `A certified copy of the original or an authenticated copy of the disclaimer's concentrates The 
        testimony should contain a declaration of truth and certainty and be written in paragraph style. `,
        icon: true,
      },
    ],
    images: designImages,
  };

  var FAQ_data = [
    {
      header: 'What is the purpose of design registration?',
      body: [
        {
          content: `To ensure that designs are original and innovative, registration of
          designs is required.`,
          icon: false,
        },
      ],
    },

    {
      header: 'How long does it take to register a design?',
      body: [
        {
          content: `The registration is valid for a maximum of ten years, with a
          possible extension of five years.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is it possible to cancel the design registration?',
      body: [
        {
          content: `Yes, you are free to cancel your design registration at any time.
          The Controller of Design must receive an appeal requesting the
          undoing of structure 8 on the related difficulties.`,
          icon: false,
        },
      ],
    },
    {
      header: 'A Register of Design is what?',
      body: [
        {
          content: `A record maintained by the Patent Office in Kolkata to keep track of
          the design number, class number, date of documentation,
          communication date, address of the Proprietor, name of the
          Proprietor, and several factors that may affect the validity of
          ownership.`,
          icon: false,
        },
      ],
    },
    {
      header: `What does "piracy of design" mean?`,
      body: [
        {
          content: `The use of a design or its imitation on an article belonging to a
          class of products in which the design has been registered with the
          intention of selling or importing such articles without the
          registered proprietor's authorization is considered design piracy.`,
          icon: false,
        },
      ],
    },
    {
      header: `What is a Design?`,
      body: [
        {
          content: `A design is referred to shape, ornament, pattern, composition 
          of line or colors, or any other visible aspect. A design can be two or three-dimensional.`,
          icon: false,
        },
      ],
    },
    {
      header: ` Is Design registration mandatory?`,
      body: [
        {
          content: `Design registration is not mandatory, having a design registration protects the design's uniqueness and authenticity.`,
          icon: false,
        },
      ],
    },
    {
      header: `Why get design registration?`,
      body: [
        {
          content: `A design registration bestows copyright on the design that is registered for 10 years 
          which can be further extended by 5 years. The owner of the design has exclusive rights to use the 
          design in the class in which it has been registered`,
          icon: false,
        },
      ],
    },
    {
      header: `When to apply for a Design registration?`,
      body: [
        {
          content: `An application for design registration should be made as soon as possible after the design
           is finalized and before it is shown to others.`,
          icon: false,
        },
      ],
    },
    {
      header: `Who can apply for Design registration?`,
      body: [
        {
          content: `Application for obtaining design registration can be made by the proprietor of any design, 
          an Assignee ( jointly or separately ), if the applicant is a non-resident Indian then his agent or
           representative should apply.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Design Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={designSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={designAboutData} />
          <Counter />
          <ScrollNav scroll_data={designScrollId} />
          <ImgContent item={designIcData} />
          <ContentForm CFSection_data={designCfData} />
          <SliderContent ScSection_data={designSCData} />
          <ContentImg CISection_data={designCiData} />

          <Cta />

          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
